import React from 'react'
import HeadingWithText from './HeadingWithText'

const TermsContent = () => {
  return (
    <div>
      <h1>Terms and Conditions</h1>
      <p><strong>Effective Date:</strong> Jan 1, 2024</p>

      <p>Welcome to GAINSS (the "App"). By installing, accessing, or using our App, you agree to be bound by these Terms and Conditions ("Terms"). Please read them carefully.</p>

      <h2>1. Acceptance of Terms</h2>
      <p>By using the App, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, do not use the App.</p>

      <h2>2. License Grant</h2>
      <p>Subject to your compliance with these Terms, we grant you a non-exclusive, non-transferable, revocable license to use the App solely for your internal business purposes.</p>

      <h2>3. Usage Restrictions</h2>
      <p>You agree not to:</p>
      <ul>
        <li>Modify, adapt, or hack the App.</li>
        <li>Use the App for any illegal or unauthorized purpose.</li>
        <li>Attempt to reverse engineer or decompile any part of the App.</li>
        <li>Distribute, resell, or lease the App to third parties.</li>
      </ul>

      <h2>4. App Updates and Modifications</h2>
      <p>We reserve the right to update or modify the App at any time without notice. Such updates may include changes to features, functionality, or user interface. Continued use of the App after any such updates or modifications constitutes your acceptance of the new Terms.</p>

      <h2>5. License Fees and Payments</h2>
      <p>If your App requires a paid license:</p>
      <ul>
        <li>You agree to pay the applicable fees as outlined on the Atlassian Marketplace.</li>
        <li>All fees are non-refundable unless stated otherwise.</li>
      </ul>

      <h2>6. License Changes</h2>
      <p>We reserve the right to change our licensing model, including moving from a free to a paid model or updating pricing tiers. These changes will apply to new users or upon license renewal. Existing users will retain their current license terms until the end of their current subscription period.</p>

      <h2>7. Data and Privacy</h2>
      <p>Your use of the App may involve the processing of personal data. Please refer to our <a href="https://www.gtentechnologies.com/privacypolicy">Privacy Policy</a> for information on how we collect, use, and protect your data.</p>

      <h2>8. Intellectual Property</h2>
      <p>All intellectual property rights in the App, including but not limited to all code, designs, logos, and trademarks, are owned by [Your Company Name]. You do not acquire any ownership rights by using the App.</p>

      <h2>9. Termination</h2>
      <p>We reserve the right to terminate or suspend your access to the App at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users of the App or us.</p>

      <h2>10. Limitation of Liability</h2>
      <p>To the maximum extent permitted by law, GTEN Technologies shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of the App.</p>

      <h2>11. Disclaimer of Warranties</h2>
      <p>The App is provided "as is" without warranty of any kind. We do not warrant that the App will meet your requirements, be uninterrupted, timely, secure, or error-free.</p>

      <h2>12. Governing Law</h2>
      <p>These Terms shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law principles.</p>

      <h2>13. Changes to Terms</h2>
      <p>We may update these Terms from time to time. If we make material changes, we will notify you by email or through a notice in the App. Your continued use of the App after any such changes constitutes your acceptance of the new Terms.</p>

      <h2>14. Contact Information</h2>
      <p>If you have any questions or concerns about these Terms, please contact us at Privacy@GTENTechnologies.com.</p>

    </div>
  )
}

export default TermsContent
